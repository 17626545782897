import { render, staticRenderFns } from "./household-ergo-a45.vue?vue&type=template&id=88e8d410&"
import script from "./household-ergo-a45.vue?vue&type=script&lang=js&"
export * from "./household-ergo-a45.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports